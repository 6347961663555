import React from 'react';
import Modal from 'react-modal';
import constant from 'constants/config';

export default ({ isModalOpen, onClose }) => {
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={onClose}
      className="cw-modal cw-modal--branddetail"
      contentLabel="Can't find your brand?"
      style={{
        overlay: {
          backgroundColor: constant.EMAIL_OVERLAY_BACKGROUND
        }
      }}
    >
      <button className="cw-modal--close" title="Close" onClick={onClose}>
        <i className="fas fa-times"></i>
      </button>
      <h3 className="cw-heading--primary mb-20">
        Can't find your brand or country?
      </h3>
      <p className="mb-10">
        If you are an active brand and market combination, you should find
        yourself listed in our tool.
        <br />
      </p>
      <p>
        If you can’t find your brand or market, raise an
        <a href={constant.KANA_URL} target="_blank" rel="noreferrer">
          {` UNA ticket `}
          <i className="fas fa-external-link-alt"></i>.
        </a>{' '}
        Send an email to{' '}
        <a href={'mailto:mrd.es-mde@unilever.com'}>mrd.es-mde@unilever.com</a>{' '}
        to get your brand and country added to the Brand POS rules.
      </p>
      <p>The following details should be provided.</p>
      <p>- Brand name and brand code (if available)</p>
      <p>- Country</p>
      <p>- Reasons on why this is needed, if and as applicable</p>
      <p>
        Once you receive a confirmation from this team, your brand and country
        will automatically reflect in Campaign Wizard within 1 working day.
      </p>
    </Modal>
  );
};

export const CantFindContryHelpLink = ({ setIsModelOpen }) => {
  return (
    <a
      href="#modal"
      onClick={event => {
        event.preventDefault();
        setIsModelOpen(true);
      }}
      title="Can't find your brand?"
    >
      Can't find your brand or country?
    </a>
  );
};
