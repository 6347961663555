import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import Datetime from 'react-datetime';
import moment from 'moment';
import {
  closeBannerModal,
  fetchBannerMessage,
  saveBannerMessage
} from '../../actions/adminConfig';
import constants from '../../constants';
import Loader from 'components/Loader';
import Alert from 'components/Alert';
import classNames from 'classnames';
import RichTextEditor from 'components/richTextEditor';

const initialData = null;

const CWBannerModal = () => {
  const dispatch = useDispatch();
  const { message, isModalOpen, isLoading } = useSelector(
    state => state.adminConfig
  );

  const [messages, setMessages] = useState(initialData);
  const [defaultMessages, setDefaultMessages] = useState(initialData);
  const [dateConflicts, setDateConflicts] = useState(false);

  useEffect(() => {
    if (isModalOpen) {
      dispatch(fetchBannerMessage());
    }
  }, [isModalOpen, dispatch]);

  useEffect(() => {
    setMessages(message || []);
    setDefaultMessages(message || []);
  }, [message]);

  const scrollToTop = () => {
    const modal = document.getElementsByClassName('cw-modal');
    if (modal && modal[0]) {
      modal[0].scroll({ top: 0, behavior: 'smooth' });
    }
  };

  const checkDateConflicts = banners => {
    const conflicts = [];
    const currentDate = new Date();
  
    for (let i = 0; i < banners.length; i++) {
      const bannerA = banners[i];
      if (!bannerA.startDate && !bannerA.endDate && !bannerA.alwaysOn) {
        continue;
      }
  
      for (let j = i + 1; j < banners.length; j++) {
        const bannerB = banners[j];
  
       
        if (!bannerB.startDate && !bannerB.endDate && !bannerB.alwaysOn) {
          continue;
        }
  
        const startA = new Date(bannerA.startDate);
        const endA = bannerA.alwaysOn || !bannerA.endDate ? null : new Date(bannerA.endDate);
        const startB = new Date(bannerB.startDate);
        const endB = bannerB.alwaysOn || !bannerB.endDate ? null : new Date(bannerB.endDate);
  
       
        const isOverlap = (
          (startA <= (endB || currentDate) && (endA || currentDate) >= startB) ||  
          (bannerA.alwaysOn && startA <= (endB || currentDate)) ||               
          (bannerB.alwaysOn && startB <= (endA || currentDate)) ||                
          (bannerA.alwaysOn && bannerB.alwaysOn)                                  
        );
  
        if (bannerA.enabled && bannerB.enabled && isOverlap) {
          conflicts.push(`Conflict between banners: Index ${i} and Index ${j}`);
        }
      }
    }
  
    if (conflicts.length > 0) {
      scrollToTop();
      return true;
    } else {
      return false;
    }
  };
  
  
  const createPayload = messages => {
    return messages.map(ele => ({
      type: 'header-banner-notification',
      _id: ele._id || null,
      message: ele.message,
      startDate: ele.startDate || null,
      endDate: ele.endDate && !ele.alwaysOn ? ele.endDate : null,
      alwaysOn: ele.alwaysOn || false
    }));
  };

  const handleSave = () => {
    let hasErrors = false;
    const updatedMessages = messages?.map((msg, index) => {
      const errors = {};
      if (msg.message || msg.startDate || msg.alwaysOn || msg.endDate) {
        if (!msg?.message || msg?.message?.trim() === '') {
          errors.message = `Message is required.`;
          hasErrors = true;
        }

        if (!msg.startDate && msg.message.trim().length > 0) {
          errors.startDate = `Start date is required.`;
          hasErrors = true;
        }

        if (!msg.alwaysOn && !msg.endDate && msg.startDate) {
          errors.endDate = `End date is required.`;
          hasErrors = true;
        }
      }

      if (
        msg.startDate &&
        msg.endDate &&
        new Date(msg.startDate) > new Date(msg.endDate)
      ) {
        errors.dateOrder = `Start date must be before or equal to end date for Message.`;
        hasErrors = true;
      }

      return { ...msg, errors };
    });

    setMessages(updatedMessages);

    if (hasErrors) {
      scrollToTop();
      return;
    }

    const dateOverlapCheck = checkDateConflicts(updatedMessages);
    if (dateOverlapCheck) {
      setDateConflicts(true);
    } else {
      setDateConflicts(false);
      const payload = createPayload(updatedMessages);
      // sessionStorage.removeItem('closedByUser');
      dispatch(saveBannerMessage({ header_message: payload }));
    }
  };

  const handleRichtextUpdateField = (index, field, richTextData) => {
    let value = richTextData.getContent();
    const updatedMessages = messages.map((msg, currIndex) =>
      currIndex === index ? { ...msg, [field]: value } : msg
    );
    if (
      updatedMessages[index] &&
      updatedMessages[index].errors &&
      updatedMessages[index].errors[field]
    ) {
      updatedMessages[index].errors[field] = false;
    }

    setMessages(updatedMessages);
  };

  const handleUpdateField = (index, field, value) => {
   
    const updatedMessages = messages.map((msg, currIndex) =>
      currIndex === index ? { ...msg, [field]: value } : msg
    );
    if (
      updatedMessages[index] &&
      updatedMessages[index].errors &&
      updatedMessages[index].errors[field]
    ) {
      updatedMessages[index].errors[field] = false;
    }

    setMessages(updatedMessages);
  };
  const handleCancel = () => {
    dispatch(closeBannerModal());
  };

  const handleAddNewMessage = () => {
    const newMessage = {
      _id: null,
      enabled: true,
      startDate: null,
      endDate: null,
      message: '',
      alwaysOn: false
    };
    setMessages([...messages, newMessage]);
  };

  // const handleDelete = (index) => {
  //   const updatedMessages = [...messages];
  //   updatedMessages.splice(index, 1);
  //   setMessages(updatedMessages);
  // };
  const handleDelete = index => {
    const updateData = [...messages];
    updateData[index]['startDate'] = null;
    updateData[index]['endDate'] = null;
    updateData[index]['message'] = '';
    updateData[index]['alwaysOn'] = false;
    setMessages(updateData);
  };

  const setNoEndDate = (e, index) => {
    const updatedMessages = [...messages];
    updatedMessages[index]['alwaysOn'] = e.target.checked;
    if (
      updatedMessages[index] &&
      updatedMessages[index].errors &&
      updatedMessages[index].errors['dateOrder']
    ) {
      updatedMessages[index].errors['dateOrder'] = '';
    }

    if (
      updatedMessages[index] &&
      updatedMessages[index].errors &&
      updatedMessages[index].errors['endDate']
    ) {
      updatedMessages[index].errors['endDate'] = false;
      updatedMessages[index].errors['dateOrder'] = false;
    }
    updatedMessages[index]['endDate'] = null;
    setMessages(updatedMessages);
  };

  return (
    <Modal
      scrollable={true}
      isOpen={isModalOpen}
      onRequestClose={handleCancel}
      className="cw-modal cw-modal--banner_message_overlay"
      contentLabel="CW Banner Message"
      style={{
        overlay: {
          backgroundColor: constants.EMAIL_OVERLAY_BACKGROUND
        }
      }}
    >
      <div className="guideline-wrapper--header">
        <p className="title"> CW Banner Message </p>
        <i className="guideline-wrapper-close-icon" onClick={handleCancel}></i>
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Alert
            alertType="saasWarningAlertMessageWithIcon"
            textMessage="The dates of the two messages overlap. Please make corrections to save changes."
            showComponent={dateConflicts}
          />
          {messages?.map((msg, index) => (
            <div key={index} className="p-3 mb-3 cw-banner-design-sections">
              <div className="mt-2">
                <div className="d-flex justify-content-between align-items-center">
                  <label>
                    {index === 0
                      ? 'Your current message'
                      : `Message ${index + 1}`}
                  </label>
                  <span className="cw-icons ml-3 trash-icon">
                    <i
                      className="far fa-trash-alt"
                      onClick={() => handleDelete(index)}
                    ></i>
                  </span>
                </div>
                <div
                  className={classNames({
                    'cw-error-focus': msg?.errors?.message
                  })}
                >
                  <RichTextEditor
                    defaultValue={
                      defaultMessages[index]?.message
                        ? defaultMessages[index]?.message
                        : ''
                    }
                    value={msg?.message}
                    parentCallback={textData => {
                      handleRichtextUpdateField(index, 'message', textData);
                    }}
                  />
                </div>
                {msg.errors?.message && (
                  <p style={{ color: 'red' }}>{msg.errors.message}</p>
                )}
              </div>

              <div className="d-flex justify-content-between align-items-start mt-3">
                <div className="me-3 w-50">
                  <label>Start Date and Time (UTC):</label>
                  <div
                    className={classNames('', {
                      'cw-error-focus': msg.errors?.startDate
                    })}
                  >
                    <Datetime
                      value={msg.startDate ? moment(msg.startDate) : ''}
                      onChange={date =>
                        handleUpdateField(
                          index,
                          'startDate',
                          moment(date).toISOString()
                        )
                      }
                      inputProps={{ placeholder: 'Select start date and time' }}
                      utc
                      timeFormat="HH:mm"
                      isValidDate={current =>
                        current.isSameOrAfter(moment(), 'day')
                      }
                    />
                  </div>
                  {msg.errors?.startDate && (
                    <p style={{ color: 'red' }}>{msg.errors.startDate}</p>
                  )}
                </div>
                <div className="w-50 ml-10">
                  <label>End Date and Time (UTC):</label>
                  <div
                    className={classNames('', {
                      'cw-error-focus': msg.errors?.endDate
                    })}
                  >
                    <Datetime
                      value={
                        msg.alwaysOn
                          ? ''
                          : msg.endDate
                          ? moment(msg.endDate)
                          : ''
                      }
                      onChange={date =>
                        handleUpdateField(
                          index,
                          'endDate',
                          moment(date).toISOString()
                        )
                      }
                      disabled={msg.alwaysOn}
                      inputProps={{
                        placeholder: 'Select end date and time',
                        disabled: msg.alwaysOn
                      }}
                      utc
                      timeFormat="HH:mm"
                      isValidDate={current => {
                        if (msg.alwaysOn) return false;
                        return msg.startDate
                          ? current.isSameOrAfter(moment(msg.startDate), 'day')
                          : true;
                      }}
                    />
                  </div>
                  {msg.errors?.endDate && (
                    <p style={{ color: 'red' }}>{msg.errors.endDate}</p>
                  )}
                  {msg.errors?.dateOrder && (
                    <p style={{ color: 'red' }}>{msg.errors.dateOrder}</p>
                  )}
                  <div className="mt-2">
                    <input
                      type="checkbox"
                      checked={msg.alwaysOn}
                      onChange={e => setNoEndDate(e, index)}
                      id={`noEndDateCheckbox-${index}`}
                    />
                    <label
                      htmlFor={`noEndDateCheckbox-${index}`}
                      className="ms-2"
                    >
                      No End Date
                    </label>
                    {msg.endDate &&
                      (() => {
                        const currentDate = moment
                          .utc(new Date())
                          .startOf('day');
                        const endDate = moment.utc(msg.endDate).startOf('day');
                        const daysDiff = endDate.diff(currentDate, 'days');
                        if (daysDiff > 1) {
                          return <p>Message expires {daysDiff} days later</p>;
                        } else if (daysDiff === 1) {
                          return <p>Message expires tomorrow</p>;
                        } else if (daysDiff === 0) {
                          return <p>Message expires today</p>;
                        } else {
                          return (
                            <p style={{ color: 'red' }}>
                              Message has expired and is no longer visible
                            </p>
                          );
                        }
                      })()}
                  </div>
                </div>
              </div>
            </div>
          ))}

          {messages && messages.length < 2 && (
            <button
              onClick={handleAddNewMessage}
              className="btn btn-primary me-3 mr-1"
            >
              Add New Message
            </button>
          )}

          <button
            className="btn btn-primary btn-custom-width mb-10 cw-print-hide text-nowrap mr-1 mt-10"
            onClick={handleSave}
            disabled={isLoading}
          >
            Save
          </button>
          <button
            className="btn btn-outline-primary btn-custom-width mb-10 cw-print-hide mr-1 mt-10"
            onClick={handleCancel}
            disabled={isLoading}
          >
            Cancel
          </button>
        </div>
      )}
    </Modal>
  );
};

export default CWBannerModal;
