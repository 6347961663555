import moment from 'moment';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import constants from 'constants/config';
import ReactHtmlParser from 'react-html-parser';
import { Icon } from 'semantic-ui-react';

/**
 * It will show a notification if configured in config.js 
 * NOTIFICATIONS: [
    {
      message:'Message',
      enabled: true,
      startDate: "16/05/2023",
      endDate: "17//2023", // visible till 30th July if end date is 01/07/2023
    }
  ]
 * @returns 
 */
function Notification({ notificationHandler, notifications = [] }) {
  const [show, toggleShow] = React.useState(true);
  useEffect(() => {
    if (!show) {
      sessionStorage.setItem('closedByUser', true);
    }
  }, [show]);
  const isClosedByUser = sessionStorage.getItem('closedByUser');
  // const closeNotification = e => {
  //   setState({ active: false });
  // };s
  return (
    <>
      {notifications.map((item, index) => {
        if (
          item.enabled &&
          item.type === 'header-banner-notification' &&
          item.message &&
          item.message.trim()
        ) {
          const toCompareDate = moment.utc(new Date()).startOf('day');
          const startDate = moment.utc(item.startDate).startOf('day');
          const endDate = item.endDate ?  moment.utc(item.endDate).startOf('day') : null;
          
          const isWithinDateRange = endDate
            ? toCompareDate.isBetween(startDate, endDate, null, '[]')
            : toCompareDate.isSameOrAfter(startDate);

          return isWithinDateRange && show && !isClosedByUser ? (
            <div className="row notification" key={index}>
              <div className="col notification--icon">
                <Icon link name="warning circle" />
              </div>
              <div className="col-11">{ReactHtmlParser(item.message)}</div>
              <div className="col notification--close">
                <Icon
                  onClick={() => {
                    toggleShow(!show);
                    notificationHandler();
                  }}
                  link
                  name="close"
                />
              </div>
            </div>
          ) : null;
        }
      })}
    </>
  );
}
export default Notification;
