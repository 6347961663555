import axios from 'axios';
import constants from '../constants';
import {
  OPEN_CW_BANNER_MODAL,
  CLOSE_CW_BANNER_MODAL,
  FETCH_BANNER_REQUEST,
  FETCH_BANNER_SUCCESS,
  FETCH_BANNER_FAILURE,
  SAVE_BANNER_REQUEST,
  SAVE_BANNER_SUCCESS,
  SAVE_BANNER_FAILURE,
  SET_ADMIN_CONFIG
} from '../actionTypes';

export const openBannerModal = () => ({
  type: OPEN_CW_BANNER_MODAL
});

export const closeBannerModal = () => ({
  type: CLOSE_CW_BANNER_MODAL
});

export const fetchBannerMessage = () => {
  return async dispatch => {
    dispatch({ type: FETCH_BANNER_REQUEST });
    try {
      const response = await axios.get(
        `${constants.serviceUrls.ADMIN_CONFIG_URL}/header-banner-notification`
      );
      
      dispatch({
        type: FETCH_BANNER_SUCCESS,
        payload: response.data.data || ''
      });
    } catch (error) {
      dispatch({ type: FETCH_BANNER_FAILURE, error: error.message });
    }
  };
};

export const saveBannerMessage = message => {
  return async dispatch => {
    dispatch({ type: SAVE_BANNER_REQUEST });
    try {
      const data = { type: 'header-banner-notification', ...message };
      const response = await axios.post(
        constants.serviceUrls.ADMIN_CONFIG_URL,
        data
      );

      dispatch({
        type: SAVE_BANNER_SUCCESS,
        payload: response.data.message || ''
      });
      
      dispatch({
        type: SET_ADMIN_CONFIG,
        payload:  response.data.data
      });
    } catch (error) {
      dispatch({ type: SAVE_BANNER_FAILURE, error: error.message });
    }
  };
};
